import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProgress, setToast } from "../../../../redux/slice/system";
import {
  axios,
  AxiosMulti,
  formatApiResult,
} from "../../../../axios/request/common";
import { AssessmentAPI } from "../../../../axios/api";
import { useAbortedEffect } from "../../../../components/hooks";

import ModuleAssessmentForm from "../../../../components/common/assessmentForm";

import { Button } from "primereact/button";

export default function ModulePopMemberAssessment({
  prePopOption,
  closePrePop,
  setPreReload,
  isManager = false,
  searchParams = {},
  action,
}) {
  const dispatch = useDispatch();
  const redux_user = useSelector((state) => state.user);
  const [examData, setExamData] = useState([]);
  const [answerData, setAnswerData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [managerData, setManagerData] = useState([]);

  useAbortedEffect(
    (signal) => {
      dispatch(setProgress(true));

      let params = {
          exam: { id: prePopOption.data.id },
          now: {
            exam_exampleid: prePopOption.data.id,
            rateder: prePopOption.data.member.username,
          },
          history: {
            year: searchParams?.year ?? null,
            season: searchParams?.season ?? null,
            exam_exampleid: prePopOption.data.id,
            rateder: prePopOption.data.member.username,
          },
        },
        promise = [
          AssessmentAPI.getExamExamplesDetails({
            data: params.exam,
            options: { signal },
          }),
        ];
      if (prePopOption.data.tab === "history")
        promise.push(action.getHistory({ signal, params: params.history }));
      else promise.push(action.getNow({ signal, params: params.now }));

      AxiosMulti(promise).then(
        axios.spread((result_exam = {}, result_detail = {}) => {
          // 考核填答資料
          let { data: result_detail_data } = result_detail;
          if (result_detail.Response === 1) {
            if (isManager) {
              const {
                id,
                bonus,
                attendance_score,
                award_score,
                punish_score,
                exam_score,
                username,
                season,
                year,
              } = result_detail_data.examResult;
              result_detail_data = {
                ...result_detail_data,
                id,
                bonus,
                attendance_score,
                award_score,
                punish_score,
                exam_score,
                username,
                season,
                year,
              };
              result_detail_data.examResult =
                result_detail_data.examResult.exam_results.reduce(
                  (pre, cur) => ({ ...pre, [cur.rater]: cur }),
                  {}
                );
            } else {
              if (!result_detail_data.exam_results_score)
                result_detail_data.exam_results_score = {};
              const { attendance_score, award_score, punish_score } =
                result_detail_data.exam_results_score;
              result_detail_data = {
                ...result_detail_data,
                attendance_score,
                award_score,
                punish_score,
              };
              result_detail_data.examResult =
                result_detail_data.examResult.reduce((pre, cur) => {
                  let [key, val] = Object.entries(cur)[0];
                  return {
                    ...pre,
                    [key]: val,
                  };
                }, {});
            }

            // if (isManager && result_detail_data) {
            result_detail_data.exam_results_details_format = Object.entries(
              result_detail_data.examResult
            )?.reduce(
              (preExam, [rater, curExam]) => ({
                ...preExam,
                [rater]: {
                  rater,
                  remark: curExam?.remark ?? "",
                  isSelf: rater === redux_user.userDetail.username,
                  details:
                    curExam?.exam_results_details.reduce(
                      (preDetail, curDetail) => ({
                        ...preDetail,
                        [curDetail.exam_itemid]: curDetail,
                      }),
                      {}
                    ) ?? {},
                },
              }),
              {}
            );
            // } else if (result_detail_data) {
            //   result_detail_data.exam_results_details_format = {
            //     [result_detail_data.rater]: {
            //       rater: result_detail_data.rater,
            //       remark: result_detail_data.remark,
            //       details: result_detail_data.exam_results_details,
            //     },
            //   };
            // }

            setAnswerData({
              data: result_detail_data,
              member: prePopOption.data.member,
              tab: prePopOption.data.tab,
            });
          }

          // 考核表單資料
          let { data: result_exam_data } = result_exam;
          if (result_exam.Response === 1) {
            setExamData(result_exam_data);
            setCategoryData(result_exam_data.exam_example_item_setting);

            // type=4 總經理 => 看全部
            let matchManager = result_exam_data.exam_example_weight.filter(
              (item) =>
                Object.keys(result_detail_data.examResult).includes(item.rater)
            );
            setManagerData(
              isManager ? result_exam_data.exam_example_weight : matchManager
            );
          }

          let isError = formatApiResult({
            data: [result_exam, result_detail],
            action: (message) => {
              dispatch(
                setToast({
                  severity: "error",
                  summary: message,
                  detail: "",
                })
              );
            },
          });
          if (isError) {
            dispatch(setProgress(false));
            return;
          }

          dispatch(setProgress(false));
        })
      );
    },
    [prePopOption]
  );

  return (
    <>
      <div className="text-center px-3">
        <ModuleAssessmentForm
          examData={examData}
          answerData={answerData}
          categoryData={categoryData}
          managerData={managerData}
          footerTemplate={
            <>
              <div className="w-full mt-3">
                <Button
                  className="p-button-secondary px-4"
                  type="button"
                  label="取消"
                  onClick={() => closePrePop({ type: "assessment" })}
                />
                {/* {redux_user.userDetail?.isAssessment && ( */}
                <Button className="px-4 ml-3" type="submit" label="送出" />
                {/* )} */}
              </div>
            </>
          }
          isPreview={false}
          isManager={isManager}
          closePrePop={closePrePop}
          setPreReload={setPreReload}
        />
      </div>
    </>
  );
}
