const option_enabled = [
  { label: "啟用", value: 1 },
  { label: "停用", value: 0 },
];

const option_yesno = [
  { label: "是", value: 1 },
  { label: "否", value: 2 },
];

const option_finish = [
  { label: "完成", value: 1 },
  { label: "未完成", value: 0 },
];

const option_member_type = [
  { label: "面試人員", value: 1 },
  { label: "在職人員", value: 2 },
  { label: "離職人員", value: 3 },
];

const option_marriage = [
  { label: "已婚", value: 1 },
  { label: "未婚", value: 2 },
  { label: "離婚", value: 3 },
  { label: "喪偶", value: 4 },
];

const option_military_service = [
  { label: "役畢", value: 1 },
  { label: "未役", value: 2 },
  { label: "無需服役", value: 3 },
];

const option_blood_type = [
  { label: "A", value: 1 },
  { label: "B", value: 2 },
  { label: "AB", value: 3 },
  { label: "O", value: 4 },
];

const option_isgraduation = [
  { label: "畢業", value: 1 },
  { label: "肄業", value: 2 },
];

const option_language_type = [
  { label: "聽", value: 1 },
  { label: "說", value: 2 },
  { label: "讀", value: 3 },
  { label: "寫", value: 4 },
];

const option_language_level = [
  { label: "精通", value: 1 },
  { label: "中等", value: 2 },
  { label: "略懂", value: 3 },
  { label: "不會", value: 4 },
];

const option_salary_type = [
  { label: "本薪", value: 1 },
  { label: "交通津貼", value: 2 },
  { label: "全勤獎金", value: 3 },
  { label: "職務/技術", value: 4 },
];

const option_award = [
  { label: "嘉獎", value: 1 },
  { label: "小功", value: 2 },
  { label: "大功", value: 3 },
];

const option_punish = [
  { label: "警告", value: 1, score: 3 },
  { label: "小過", value: 2, score: 6 },
  { label: "大過", value: 3, score: 10 },
  { label: "遲到扣分", value: 4, score: 2 },
  // { label: "早退", value: 5, score: 2 },
  // { label: "曠職", value: 6, score: 10 },
];

const option_leave_work = [
  { label: "特休假", value: "特休假" },
  { label: "公假", value: "公假" },
];

const option_season = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
];

const option_system_startYear = 2024;

export {
  option_enabled,
  option_yesno,
  option_finish,
  option_member_type,
  option_marriage,
  option_military_service,
  option_blood_type,
  option_isgraduation,
  option_language_type,
  option_language_level,
  option_salary_type,
  option_award,
  option_punish,
  option_season,
  option_leave_work,
  option_system_startYear,
};
